var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.dnsMismatch)?_c('v-btn',{staticClass:"mr-4",attrs:{"elevation":"0","outlined":"","x-small":"","color":"error","loading":_vm.loading},on:{"click":_vm.loadModal}},[_c('v-icon',{attrs:{"size":"24"}},[_vm._v("$alertwarning")]),_c('span',{staticClass:"text--heading p-4 font-weight-bold"},[_vm._v(_vm._s(_vm.$t("message.dnsPropagationInProgress")))])],1):(
      !_vm.dnsMismatch && _vm.instance.getUserPrivileges('general.change_domain')
    )?_c('v-btn',{staticClass:"mr-4",attrs:{"elevation":"0","outlined":"","x-small":"","color":"primary","loading":_vm.loading},on:{"click":_vm.loadModal}},[_c('span',{staticClass:"text--heading p-4 font-weight-bold"},[_vm._v(_vm._s(_vm.$t("button.setDomain.changeDomain")))])]):_vm._e(),_c('v-dialog',{ref:"dialog",attrs:{"transition":"custom-dialog-transition"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('div',{staticClass:"card-overlay",on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}}),_c('v-card',{staticStyle:{"width":"560px"}},[_c('v-card-title',{staticClass:"pb-6 flex justify-space-between align-center"},[_c('h4',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(_vm.$t("heading.instance.modal.setDomain.title"))+" ")]),_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){_vm.isOpen = false}}},[_c('v-icon',{attrs:{"size":"24"}},[_vm._v("$close")])],1)],1),(_vm.step === 0)?[_c('v-card-text',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.nextStep.apply(null, arguments)}}},[_c('v-expansion-panels',{model:{value:(_vm.panelOpen),callback:function ($$v) {_vm.panelOpen=$$v},expression:"panelOpen"}},[_c('v-expansion-panel',{attrs:{"elevation":"0"}},[_c('v-expansion-panel-header',{staticClass:"h6 text--heading font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("heading.instance.modal.setDomain.set.domain.title"))+" ")]),_c('v-expansion-panel-content',[_vm._v(" "+_vm._s(_vm.$t("form.label.domainName"))+" "),(_vm.orderDomainLink)?_c('a',{staticClass:"float-right info--text",attrs:{"target":"_blank","href":_vm.orderDomainLink}},[_vm._v(" "+_vm._s(_vm.$t("button.domain.order"))+" ")]):_vm._e(),(!_vm.panelOpen)?_c('v-text-field',{staticClass:"mt-2",attrs:{"outlined":"","placeholder":"mydomain.com","hide-details":"","validate-on-blur":"","rules":[
                      (v) =>
                        !!v ||
                        _vm.$t('validation.required', {
                          field: _vm.$t('form.label.domainName'),
                        }),
                      (v) =>
                        _vm.domainRegex.test(v) ||
                        _vm.$t('validation.valid', {
                          field: _vm.$t('form.label.domainName'),
                        }),
                    ]},model:{value:(_vm.clientDomainName),callback:function ($$v) {_vm.clientDomainName=$$v},expression:"clientDomainName"}}):_vm._e()],1)],1),(_vm.freeSubdomains.length > 0)?_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"h6 text--heading font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("heading.instance.modal.setDomain.set.subdomain.title"))+" ")]),_c('v-expansion-panel-content',[(_vm.panelOpen === 1)?_c('div',{staticClass:"flex flex-row align-center",staticStyle:{"display":"flex"}},[_c('v-text-field',{attrs:{"outlined":"","placeholder":"mydomain","hide-details":"","validate-on-blur":"","max-width":"250px","rules":[
                        (v) =>
                          !!v ||
                          _vm.$t('validation.required', {
                            field: _vm.$t('general.subdomain'),
                          }),
                      ]},model:{value:(_vm.freeDomainName),callback:function ($$v) {_vm.freeDomainName=$$v},expression:"freeDomainName"}}),_c('span',{staticClass:"mx-2 mb-6"},[_vm._v(".")]),_c('v-select',{attrs:{"outlined":"","items":_vm.freeSubdomains,"hide-details":"","validate-on-blur":"","max-width":"250px","rules":[
                        (v) =>
                          !!v ||
                          _vm.$t('validation.required', {
                            field: _vm.$t('form.label.domain'),
                          }),
                      ],"menu-props":{
                        offsetY: true,
                        nudgeBottom: '8px',
                        closeOnContentClick: false,
                        contentClass:
                          'custom-dropdown-select custom-dropdown-select--onboarding',
                      }},model:{value:(_vm.freeDomainAffix),callback:function ($$v) {_vm.freeDomainAffix=$$v},expression:"freeDomainAffix"}})],1):_vm._e()])],1):_vm._e()],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex flex-column pb-4 px-8 pt-6"},[_c('v-btn',{attrs:{"x-large":"","elevation":"0","color":"primary","block":"","loading":_vm.submitting},on:{"click":_vm.nextStep}},[_vm._v(" "+_vm._s(_vm.$t("button.setDomain.useThisDomain"))+" ")]),_c('v-btn',{staticClass:"mx-0 mt-2",attrs:{"x-large":"","elevation":"0","color":"gray","text":"","block":""},on:{"click":function($event){_vm.isOpen = false}}},[_c('span',{staticClass:"p-1 font-weight-light gray--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.$t("button.cancel"))+" ")])])],1)]:(_vm.step === 1)?[_c('v-card-text',[_c('div',{staticClass:"domain-container"},[_c('span',{staticClass:"p-3"},[_vm._v(" "+_vm._s(_vm.$t("heading.instance.modal.setDomain.config.subtitle"))+" "),_c('span',{staticClass:"text--heading font-weight-600"},[_vm._v(" "+_vm._s(_vm.instance.domain)+" ")])]),(_vm.instance.getUserPrivileges('general.change_domain'))?_c('v-btn',{attrs:{"color":"primary","x-small":"","outlined":"","elevation":"0"},on:{"click":function($event){_vm.step = 0}}},[_vm._v(" "+_vm._s(_vm.$t("button.change"))+" ")]):_vm._e()],1),_c('div',{staticClass:"domain-info"},[_c('v-tabs',{staticClass:"mb-4",attrs:{"color":"primary"}},[_c('v-tab',[(
                    _vm.instance?.dns_settings?.domain_verification ==
                    'resolve_a_record'
                  )?[_vm._v(" "+_vm._s(_vm.$t( "heading.instance.modal.setDomain.config.tab.dnsRecords.title" ))+" ")]:(
                    _vm.instance.dns_settings?.domain_verification ==
                    'resolve_ns_records'
                  )?[_vm._v(" "+_vm._s(_vm.$t( "heading.instance.modal.setDomain.config.tab.nameservers.title" ))+" ")]:(
                    _vm.instance.dns_settings?.domain_verification ==
                    'resolve_cname_record'
                  )?[_vm._v(" "+_vm._s(_vm.$t( "heading.instance.modal.setDomain.config.tab.cname.title" ))+" ")]:_vm._e()],2)],1),(
                _vm.instance.dns_settings?.domain_verification ==
                'resolve_a_record'
              )?_c('div',[_c('ol',[_c('li',[_vm._v(" "+_vm._s(_vm.$t( "heading.instance.modal.setDomain.config.tab.dnsRecords.list.first" ))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.$t( "heading.instance.modal.setDomain.config.tab.dnsRecords.list.second" ))+" "),_c('ol',[_vm._v(" "+_vm._s(_vm.$t("general.name"))+": "),_c('strong',[_vm._v(_vm._s(_vm.instance.domain))]),_vm._v(" ("+_vm._s(_vm.$t("general.or"))+" "),_c('strong',[_vm._v("@")]),_vm._v(") "),_c('br'),_vm._v(_vm._s(_vm.$t("general.type"))+": "),_c('strong',[_vm._v("A")]),_c('br'),_vm._v(_vm._s(_vm.$t("general.address"))+": "),_c('strong',[_vm._v(_vm._s(_vm.ipAddress))]),_c('br'),_vm._v("--- "),_c('br'),_vm._v(_vm._s(_vm.$t("general.name"))+": "),_c('strong',[_vm._v("www."+_vm._s(_vm.instance.domain))]),_c('br'),_vm._v(_vm._s(_vm.$t("general.type"))+": "),_c('strong',[_vm._v("CNAME")]),_c('br'),_vm._v(_vm._s(_vm.$t("general.address"))+": "),_c('strong',[_vm._v(_vm._s(_vm.instance.domain))])])]),_c('li',[_vm._v(" "+_vm._s(_vm.$t( "heading.instance.modal.setDomain.config.tab.dnsRecords.list.third" ))+" ")])])]):_vm._e(),(
                _vm.instance.dns_settings?.domain_verification ==
                'resolve_ns_records'
              )?_c('div',[_c('ol',[_c('li',[_vm._v(" "+_vm._s(_vm.$t( "heading.instance.modal.setDomain.config.tab.dnsRecords.list.first" ))+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.$t( "heading.instance.modal.setDomain.config.tab.nameservers.list.second" ))+" "),_vm._l((_vm.nameservers),function(ns,index){return _c('ol',{key:index},[_c('strong',[_vm._v(_vm._s(ns))])])})],2),_c('li',[_vm._v(" "+_vm._s(_vm.$t( "heading.instance.modal.setDomain.config.tab.dnsRecords.list.third" ))+" ")]),_c('li',[_c('strong',[_vm._v(_vm._s(_vm.$t( "heading.instance.modal.setDomain.config.tab.nameservers.list.note_label" )))]),_vm._v(" "+_vm._s(_vm.$t( "heading.instance.modal.setDomain.config.tab.nameservers.list.note_text" ))+" ")])])]):_vm._e(),(
                _vm.instance.dns_settings?.domain_verification ==
                'resolve_cname_record'
              )?_c('div',[_c('p',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t( "heading.instance.modal.setDomain.config.tab.cname.verification_failed" ))+" ")])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( "heading.instance.modal.setDomain.config.tab.cname.detected_address" ))+": "),_c('strong',[_vm._v(_vm._s(_vm.resolvedCnameAddress))])])]):_vm._e()],1)])]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }